@import 'styles/config';

.heroCarouselIndicators {
  display: flex;

  position: absolute;
  bottom: 10px;
  z-index: 0;

  margin-left: calc(var(--container-gutter) - 9px);
  margin-right: var(--container-gutter);

  color: var(--color-hero-carousel-indicators, var(--color-font-contrast));

  /* stylelint-disable-next-line order/order */
  @include desktop-landscape-breakpoint {
    justify-content: center;

    bottom: 20px;
  }
}
