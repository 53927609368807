@import 'styles/config';

.backgroundGradient {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 100%;

  opacity: 0;

  background: var(--fill-gradient);

  transition: all 0.15s ease-in-out;
}

.lightningButton {

  @include hover-supported() {
    transition: all 0.15s ease-in-out;

    .backgroundGradient {
      opacity: 1;

      transition: all 0.15s ease-in-out;
    }
  }
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  position: relative;

  padding: 18.5px 24px;

  height: 64px;
  overflow: hidden;

  border-radius: 8px;

  font-size: #{$Px18Clamp};
  font-weight: 700;
  line-height: 150%;
  text-decoration: none;

  background: var(--ui-button-button-fill);
  color: white;
}

.cardIsOnHover{
  transition: all 0.15s ease-in-out;
    
  .backgroundGradient {
    opacity: 1;

    transition: all 0.15s ease-in-out;
  }
}

.buttonSmall {
  padding: 16px 18.5px;

  height: 56px;
}

.buttonTextLarge {
  display: flex;
  align-items: center;
  gap: #{$Px12Clamp};

  z-index: 2;
}

.lightningWrapper {
  display: flex;
  align-items: flex-end;
}