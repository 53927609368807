@import 'styles/config';

.cardGrid {
  --cols: 1;
  --row-gutter: var(--gutter);

  position: relative;
  z-index: 0;

  @media (min-width: $min-tablet) {
    --cols: 2;
  }

  @media (min-width: $min-wide) {
    --cols: 3;
  }

  & &__header {
    margin-bottom: scale-to-em(15);
  }

  &__grid {
    @include container;

    display: grid;
    gap: var(--row-gutter) var(--gutter);
    grid-template-rows: auto;
    grid-template-columns: repeat(var(--cols), minmax(0, 1fr));
  }

  &__item {
    display: flex;
    align-items: stretch;

    opacity: 0;

    @media (prefers-reduced-motion: reduce) {
      opacity: 1;
    }
  }
}
