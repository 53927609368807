@import 'styles/config';

.heroCarouselItem {
  --carousel-transition-duration: #{$animation-duration * 0.5};
  grid-area: 1 / -1;

  contain: layout;

  z-index: -1;

  height: 100%;
  width: 100%;

  // matches .heroCarousel

  // overflow: hidden;

  background-color: var(--color-background-alt);

  color: var(--color-hero-font);

  /* stylelint-disable-next-line order/order */
  @media (min-width: $min-tablet) {
    // @include desktop-landscape-breakpoint {
    @include section;

    --carousel-transition-duration: #{$animation-duration * 0.75};

    height: 100%;
    overflow: hidden;

    &::before {
      content: '';

      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      z-index: 1;

      height: 110px;

      pointer-events: none;
    }
  }

  &.hasVideo {
    @media (max-width: $max-tablet) {
      --color-hero-font: $color-font;
    }
  }

  &.appear,
  &.appearActive,
  &.appearDone {
    opacity: 1;
  }

  &:first-child {
    z-index: 0;
  }

  &.animate {
    &.enter {
      clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);

      opacity: 0.7;
    }

    &.reverse.enter {
      clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    }

    &.enterActive {
      clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);

      opacity: 1;

      transition: clip-path calc(var(--carousel-transition-duration) * 1.5)
        $ease-out 50ms,
        opacity var(--carousel-transition-duration) $ease-out;
    }
  }

  &.reverse.enterActive {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  &.enter,
  &.enterActive,
  &.enterDone {
    z-index: 1;
  }

  &.exit,
  &.exitActive {
    z-index: 0;
  }

  &.exitDone {
    z-index: -1;
  }

  // matches .heroCarousel__indicators
  &__mediaWrap {
    position: relative;
    z-index: -1;
    
    max-height: calc(100vh - var(--header-height));

    overflow: hidden;

    border-radius: 8px;

    background-color: var(--color-hero-background);

    .hasVideo & {
      @media (max-width: $max-mobile) {
        max-height: 60vh;
      }
    }

    &::before {
      @include aspect-ratio(1, 1);
      content: '';

      .hasVideo & {
        padding-bottom: calc(var(--video-aspect-ratio, 0.5625) * 100%);
        @include aspect-ratio(16, 11);
      }
    }

    /* stylelint-disale-next-line order/order */
    @media (min-width: $min-tablet) {
      // @include desktop-landscape-breakpoint {
      @include fit;
    }
  }

  &:not(.hasVideo) &__mediaWrap {
    @include fit;
  }

  &__media {
    @include fit;

    z-index: -1;

    min-height: 100%;

    object-fit: cover;
    //@include object-position(70, 50);
    object-position: top center;

    user-select: none;
    pointer-events: none;

    backface-visibility: hidden;

    &::before {
      display: none;
    }

    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }
  }

  &__grid {
    @include grid;

    @media (min-width: $min-tablet) {
      min-height: 100%;
    }

    align-items: center;
  }

  &:not(.hasVideo) &__grid {
    min-height: 100%;
  }

  &__contentWrap {
    display: flex;
    grid-column-start: 1;
    gap: #{$Px32Clamp};

    position: absolute;
    bottom: clamp(0%, 10vw, 20%);

    padding: #{$Px32Clamp};

    padding-top: $gutter;

    width: 100%;
    max-width: min-content;
    min-width: min(518px, 80vw);

    border-radius: 8px;

    background: $color-background;
    box-shadow: 16px 16px 0px rgba(70, 194, 140, 1);
    color: #{$color-secondary};

    transform: translate3d(0, 0, 0);
    @include grid-item;
    --color-link-underline: var(--color-primary);
  }

  &__contentWrapWithVideo {
    max-width: 560px;
  }

  &__content {
    @include grid-item;
    @include responsive-font(26px, 27px);

    // Enter words
    .animate.enter &,
    .animate.appear & {
      opacity: 0;

      transform: translateY(0.5em);
    }

    .animate.enterActive &,
    .animate.appearActive & {
      opacity: 1;

      transform: none;

      transition: transform var(--carousel-transition-duration) $ease-out,
        opacity var(--carousel-transition-duration) $ease-out;

      transition-delay: calc(var(--carousel-transition-duration) * 1.5);

      @media (prefers-reduced-motion: reduce) {
        transition: none;
      }
    }

    .animate.enterDone &,
    .animate.exit & {
      opacity: 1;
    }

    .animate.exitActive & {
      opacity: 0;

      transition: opacity var(--carousel-transition-duration) $ease-out;
    }
  }

  & &__text {
    font-size: #{$Px20Clamp};

    font-weight: 400;
    line-height: 110%;

    letter-spacing: -0.4px;

    em {
      font-style: normal;
    }

    b,
    strong {
      font-weight: inherit;
    }
  }

  &__link {
    padding-top: max(25px, min(5vmin, 30px));

    max-width: 250px;

    &.animate {
      .enter &,
      .appear & {
        opacity: 0;
      }

      .enterActive &,
      .appearActive & {
        opacity: 0.99;

        transition: opacity calc(var(--carousel-transition-duration) * 0.5)
          linear;
        transition-delay: calc(var(--carousel-transition-duration) * 2.15);

        @media (prefers-reduced-motion: reduce) {
          transition: none;
        }
      }

      .enterDone &,
      .exit & {
        opacity: 0.99;
      }

      .exitActive & {
        opacity: 0;

        transition: opacity calc(var(--carousel-transition-duration) * 0.75)
          $ease-out;

        @media (prefers-reduced-motion: reduce) {
          transition: none;
        }
      }
    }

    .enter & {
      opacity: 1;
    }

    @media (min-width: $hero-carousel-max-width) {
      @include grid-item(7);
    }
  }

  &__title {
    @include responsive-font(40px, $h2-size);
    margin-bottom: scale-to-em(26px, $h1-size);

    max-width: 250px;

    line-height: 1.1;

    > b {
      font-weight: 900;
    }
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
}

.ctaVideo {
  display: flex;

  position: absolute;
  top: -80px;

  width: 180px;
  height: 160%;
}
