@import 'styles/config';

.articleCard {
  position: relative;

  width: 100%;

  &__imageWrap {
    position: relative;

    overflow: hidden;

    border-radius: 8px;

    &::before {
      @include aspect-ratio(562, 402);

      content: '';

      .square & {
        @include aspect-ratio(1, 1);
      }
    }
  }

  &__image {
    @include fit;
    @include media-position;

    transform: scale(1);

    transition: transform $transition-duration $ease-out-cubic;
  }

  &:focus-within &__image,
  &:hover &__image {
    transform: scale(1.05);

    transition-duration: $animation-duration;
  }

  &__content {
    padding-top: var(--gutter);
  }

  &__title {
    margin-bottom: scale-to-em(16px, $h3-size);

    color: $color-font;

    &:not(:first-child) {
      margin-top: scale-to-em(8px, $h3-size);
    }
  }

  &__date {
    display: block;

    font-size: scale-to-em($font-size-xs, $font-size);

    color: $color-meta;
  }

  &__link {
    text-decoration: none;

    &::before {
      @include fit;
      content: '';
    }

    &:focus {
      outline: none;
    }
  }
}
