@import 'styles/config';

.sectionHeader {
  padding-bottom: scale-to-em(101px);

  &__title {
    margin-right: var(--gutter);

    color: $color-font;

    &::after {
      content: '';

      display: block;

      margin: 28px 0;

      width: scale-to-em(80px, $h2-size);
      height: 4px;

      background-color: $color-primary;
    }
  }
}
