@import 'styles/config';

.heroCarouselIndicator {
  display: block;

  position: relative;
  z-index: 0;

  width: 48px;
  height: 48px;

  &.active {
    z-index: 1;
  }

  &::before,
  &::after {
    @include fit;

    content: '';

    margin: auto;

    pointer-events: none;
  }

  // dot
  &.active::before {
    width: 12px;
    height: 12px;

    background-color: var(--color-primary);

    .animate & {
      transition: all $transition-duration $default-ease;
    }
  }

  &::before {
    width: 8px;
    height: 8px;

    border-radius: 50%;

    opacity: 0.8;

    background-color: currentColor;

    // transition: opacity $transition-duration $default-ease,
    //   background-color $transition-duration;

    /* stylelint-disable-next-line order/order */
    @include desktop-landscape-breakpoint {
      width: 8px;
      height: 8px;
    }
  }

  &::after {
    @mixin active {
      opacity: 0.4;

      transition-duration: $animation-duration;
    }

    width: 26px;
    height: 26px;

    border: 1px solid currentColor;

    border-radius: 50%;

    opacity: 0;

    .animate & {
      transition: opacity $animation-duration $default-ease;
    }
  }

  &__text {
    @include visually-hidden;
  }
}
