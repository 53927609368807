@import 'styles/config';

.heroCarousel {
  contain: layout;
  contain-intrinsic-size: 100dvh;

  position: relative;
  z-index: 0;

  // margin-top: calc(var(--header-height) * -1);
  // lighthouse mobile score imporoves with this,
  // assume helps "First Contentful Paint" as other values
  // youd think would be effected (LCP)
  // remain pretty average
  // min-height: 70dvh;
  // max-height: calc(100dvh - var(--header-height));

  background-color: var(--color-background-alt);

  // aspect-ratio: 16/9;

  /* stylelint-disable-next-line order/order */
  @include desktop-landscape-breakpoint {
    display: flex;
    align-items: stretch;

    // height: 100vh;
    min-height: 780px;

    background-color: var(--color-hero-background);
  }

  @media (max-width: $max-mobile) {
    margin-top: 0;

    // min-height: 60dvh;
  }

  &__inner {
    display: grid;

    position: relative;
    height: 70vh;
    width: 100%;

    .animate & {
      @include fade-enter;
      animation-duration: 1s;
    }
  }

  // prev / next
  &__controls {
    display: none;

    /* stylelint-disable-next-line order/order */
    @include desktop-landscape-breakpoint {
      @include fit;
      @include fade-enter;

      // --color-arrow-button-border: var(--color-primary);
      --color-arrow-button: var(--color-font-contrast);
      --opacity-arrow-button: 1;
      --arrow-button-background-opacity: 0.15;
      --arrow-button-background-opacity-hover: 0.25;

      display: flex;
      align-items: center;
      justify-content: space-between;

      z-index: 1;

      padding: 0 var(--gutter);

      pointer-events: none;

      animation-duration: 1s;
      animation-delay: $animation-duration;

      .dark & {
        --color-arrow-button: var(--color-font);
      }
    }
  }

  // matches .heroCarouselItem__imageWrap
  &__indicators {
    --color-hero-carousel-indicators: var(--color-font-contrast);

    align-self: start;

    position: absolute;
    bottom: 0;

    z-index: 1;

    width: 100%;

    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }

    &::before {
      content: '';

      flex: none;
      display: block;

      height: 70px;

      width: 100%;

      pointer-events: none;
    }

    .animate & {
      @include fade-enter;
    }

    .dark & {
      --color-hero-carousel-indicators: var(--color-font);
      --color-hero-carousel-indicators-accent: var(--color-secondary);
    }

    /* stylelint-disable-next-line order/order */
    @include tablet-landscape-breakpoint {
      display: flex;
      justify-content: center;

      top: auto;

      max-height: none;

      overflow: visible;

      &::before {
        display: none;
      }
    }
  }
}

.newHeroLayout {
  padding: 0;
  aspect-ratio: 16/9;
  overflow: hidden;
  object-fit: contain;
  border-radius: 8px;
}